<template>
    <b-modal id="modal-comment" :title="modalTitle" hide-footer size="lg" centered>
        <div class="card-body">
            <label>
                Avez-vous un commentaire, exclusivement à usage interne, à faire concernant cette vente ?
            </label>
            <div class="form-group mb-3">
                <textarea
                    class="form-control"
                    v-model="sell.comment"
                    rows="4"
                ></textarea>
            </div>
            <div class="text-end">
                <button @click.prevent="closeComment()" :disabled="processing" class="btn btn-light me-sm-2 me-0">
                    Fermer
                    <i class="uil uil-times ms-2"></i>
                </button>
                <button @click.prevent="addComment()" :disabled="processing" class="btn btn-primary mt-sm-0 mt-2">
                    Ajouter le commentaire
                    <i class="uil uil-plus ms-2"></i>
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import api from '../api/api';
    import Utilities from "../services/utilities";

    export default {
        name: 'AddCustomer',
        data() {
            return {
                modalTitle: 'Commentaire interne',
                processing: false
            }
        },
        props: ['sell'],
        methods: {
            addComment: function() {
                if (this.processing === true) {
                    return;
                }

                this.processing = true;
                
                api
                    .patch('/sales/' + this.sell.id, 
                    {
                        comment: this.sell.comment
                    }, 
                    {
                        headers: {
                            'Content-Type': 'application/merge-patch+json'
                        }
                    })
                    .then(
                        (response) => {
                            if (response && response.status == 200) {
                                this.$emit('comment-added');
                                this.processing = false;
                            }
                        },
                        () => {
                            Utilities.errormsg();
                            this.processing = false;
                        }
                    )
            },
            closeComment() {
                this.$router.push('/sales');
            }
        }
    }
</script>