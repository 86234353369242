<template>
  <Layout :show-admin="showAdmin" :show-super-admin="showSuperAdmin" :show-super-sales="showSuperSales">
    <PageHeader :title="title" :items="items" />
    <div class="row mb-4">
      <div class="col text-end">
        <button @click="saveAndRedirectToPDF()" class="btn btn-success ms-1" v-if="sellLoaded" :disabled="processing">
          Voir le PDF <i class="uil uil-arrow-up-right ms-1"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div id="worksheet-accordion" class="custom-accordion">

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="sell-customer-collapse"
              v-b-toggle.accordion-1
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">Client <b-spinner class="align-middle ms-3" variant="primary" v-if="!sellLoaded"></b-spinner></h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#worksheet-accordion"
              id="accordion-1"
              accordion="my-accordion"
              v-if="sellLoaded"
            >
              <div class="p-4 border-top">
                
                <div class="row mb-0">
                    <div class="col-md-4">
                        <p class="mb-1">Nom :</p>
                        <h5 class="font-size-16">{{ sell.customer.firstname }} {{ sell.customer.lastname }} <span class="ms-2 badge bg-soft-primary font-size-12">{{ sell.customer.status }}</span></h5>
                    </div>
                    <div class="col-md-4 mt-md-0 mt-3">
                        <p class="mb-1">Adresse :</p>
                        <h5 class="font-size-16">{{ sell.customer.address }}<br>{{ sell.customer.postcode }} {{ sell.customer.city }}</h5>
                    </div>
                    <div class="col-md-4 mt-md-0 mt-3">
                        <p class="mb-1">E-mail :</p>
                        <h5 class="font-size-16"><a :href="'mailto:' + sell.customer.email">{{ sell.customer.email }}</a></h5>
                    </div>
                </div>
                <div class="row mt-md-4 mb-0 mt-3">
                    <div class="col-md-4">
                        <p class="mb-1">Téléphone :</p>
                        <h5 class="font-size-16"><a :href="'tel:' + sell.customer.phone">{{ sell.customer.phone }}</a></h5>
                    </div>
                    <div class="col-md-4 mt-md-0 mt-3">
                        <p class="mb-1">Téléphone portable :</p>
                        <h5 class="font-size-16"><a :href="'tel:' + sell.customer.mobile">{{ sell.customer.mobile }}</a></h5>
                    </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="sell-info-collapse"
              v-b-toggle.accordion-2
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        02
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">Prestations</h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#worksheet-accordion"
              id="accordion-2"
              visible
              accordion="my-accordion"
              v-if="sellLoaded"
            >
              <div class="p-4 border-top">
                <div class="row">
                    <div class="col-md-3 pe-md-5">
                        <p class="mb-1">Énoncé de la tâche* :</p>
                        <input
                            v-model="worksheet.task"
                            type="text"
                            name="task"
                            class="form-control"
                            required
                        />
                    </div>
                    <div class="col-md-3 mt-md-0 mt-3 pe-md-5">
                        <p class="mb-1">Surface* :</p>
                        <div class="input-group">
                            <input
                                v-model="worksheet.surface"
                                type="number"
                                name="surface"
                                class="form-control"
                                required
                            />
                            <div class="input-group-append">
                                <span class="input-group-text">m<sup>2</sup>/ml</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                        <p class="mb-1">Catégorie* :</p>
                        <select class="form-select" v-model="chosenCategory">
                            <option :value="category.id" v-for="category in categories" :key="category.id" required>{{ category.name }}</option>
                        </select>
                    </div>
                </div>
              </div>
            </b-collapse>
          </div>
          
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="sell-info-collapse"
              v-b-toggle.accordion-3
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        03
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">Descriptif technique*</h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#worksheet-accordion"
              id="accordion-3"
              accordion="my-accordion"
              v-if="sellLoaded"
            >
              <div class="p-4 border-top">
                
                <div class="row mb-0">
                    <div class="col-md-12">
                        <WorksheetContent :content="worksheet.content" :exclude="false" :main-category="chosenCategory"></WorksheetContent>
                    </div>
                </div>
              </div>
            </b-collapse>
          </div>
          
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="sell-info-collapse"
              v-b-toggle.accordion-4
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        04
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">Point de contrôle</h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#worksheet-accordion"
              id="accordion-4"
              accordion="my-accordion"
              v-if="sellLoaded"
            >
              <div class="p-4 border-top">
                
                <div class="row mb-0">
                    <div class="col-md-12">
                        <WorksheetContent :content="worksheet.content" :exclude="true" :main-category="chosenCategory"></WorksheetContent>
                    </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="sell-img-collapse"
              v-b-toggle.accordion-5
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        05
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Accessibilité</h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-5"
              accordion="my-accordion"
              data-parent="#worksheet-accordion"
            >
              <div class="p-4 border-top">
                <div class="row">
                    <div class="col-md-3 pe-md-5">
                        <p class="mb-1">Accès camion :</p>
                        <input
                            v-model="worksheet.accessibility"
                            type="text"
                            name="accessibility"
                            class="form-control"
                            required
                        />
                    </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              v-b-toggle.accordion-6
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        06
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Précisions complémentaires</h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-6"
              accordion="my-accordion"
              data-parent="#worksheet-accordion"
            >
              <div class="p-4 border-top">
                <form>
                  <div class="row">
                    <div class="col-12">
                      <textarea
                        class="form-control"
                        v-model="worksheet.comment"
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                </form>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <CommentSell :sell="sell" @comment-added="onCommentAdded" />
        <a 
          class="btn btn-outline-dark me-2"
          :href="`/sell/${$route.params.id}`"
          v-if="showSuperAdmin"
        >
          <i class="fa fa-chevron-left me-2"></i> Retour à la vente
        </a>
        <button
            class="btn btn-light me-2"
            @click="finishSell()"
            :disabled="processing"
            v-if="!showSuperAdmin"
        >
          <i class="fa fa-check me-2"></i> Terminer
        </button>
        <button :disabled="processing" @click="saveWorksheet()" class="btn btn-success ms-1" v-if="showAdmin || showSuperSales">
          <i class="fa fa-save me-2"></i> Sauvegarder
        </button>
        <button :disabled="processing" @click="saveAndRedirectToQuotation()" class="btn btn-success ms-1" v-else>
          <i class="fa fa-print me-2"></i> Imprimer le devis
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
  import Swal from "sweetalert2";
  import api from '@/api/api';

  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import Utilities from "@/services/utilities";
  import SellService from "@/services/sell.service";
  import WorksheetContent from "@/forms/worksheet/worksheet-content";
  import CommentSell from "@/forms/comment-sell";

  import "vue2-dropzone/dist/vue2Dropzone.min.css";

  export default {
    page: {
      title: "Fiche chantier",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    components: {
      Layout,
      PageHeader,
      CommentSell,
      WorksheetContent
    },
    data() {
      return {
        processing: false,
        sell: {},
        SellService: SellService,
        title: "Détail d'une fiche chantier",
        items: [
          {
              text: "Accueil",
              to: "/"
          },
          {
              text: "Ventes",
              to: "/sales"
          },
          {
              text: this.getSellNumber(),
              to: this.getSellLink()
          },
          {
            text: "Détail d'une fiche chantier",
            active: true,
          },
        ],
        sellLoaded: false,
        categories: [],
        chosenCategory: null,
        formattedWorkDate: '',
        type: 'create',
        worksheet: {
            task: '',
            surface: null,
            comment: '',
            accessibility: '',
            content: {
                toiture: {
                    tileType: '',
                    tileColor: '',
                    structure: '',
                    gutter: '',
                    heightFront: '',
                    heightBack: '',
                    ridgeTiling: '',
                    scaffolding: ''
                },
                isolation: {
                    accessibleAttic: '',
                    lostAttic: '',
                    arrowHeight: '',
                    rafter: '',
                    insulatorToRemove: '',
                    insulatorToAdd: '',
                    ventilation: '',
                    shuntBox: '',
                    shaft: '',
                    hpv: '',
                    trap: '',
                    floor: '',
                    hole: ''
                },
                facade: {
                    wallType: '',
                    wallThickness: '',
                    coveringType: '',
                    coveringColor: '',
                    injectorColor: '',
                    scaffolding: ''
                }
            }
        }
      };
    },
    props: ["showAdmin", "showSuperAdmin", "showSuperSales"],
    mounted() {
      if (this.$route.params.id) {
        this.loadSell(this.$route.params.id);
      }
    },
    methods: {
        getSellNumber() {
            if (this.sell && this.sell.id) {
                return 'Vente n°' + SellService.getNumber(this.sell.sellId, this.sell.created_date, this.sell.company);
            }
            return 'Vente';
        },
        getSellLink() {
            if (this.showAdmin || this.showSuperSales) {
                return "/sell/" + this.$route.params.id;
            }
            return "/sell/" + this.$route.params.id + "/quotation";
        },
        loadServiceCategories() {
            api
                .get('/service_categories')
                .then(
                    (response) => {
                        if (response && response.status == 200) {
                            this.categories = response.data;
                            this.chosenCategory = this.categories[0].id;
                            if (this.type == 'update') {
                                this.chosenCategory = this.sell.workSheet.serviceCategory.id;
                            } else if (this.type == 'create') {
                                this.worksheet.category = '/api/service_categories/' + this.chosenCategory;
                            }
                        }
                    },
                    () => {
                        Utilities.errormsg();
                    }
                )
        },
        loadSell(id) {
            api
                .get('/sales/' + id)
                .then(
                    (response) => {
                        if (response && response.status == 200) {
                            this.sell = response.data;
                            this.sellLoaded = true;
                            this.$set(this.items[2], 'text', this.getSellNumber())
                            if (this.sell.workSheet) {
                                this.type = 'update';
                                this.worksheet = {...this.sell.workSheet};
                            }
                            this.loadServiceCategories();
                        }
                    },
                    () => {
                        Utilities.errormsg();
                        this.sellLoaded = true;
                    }
                )
        },
        worksheetValid() {
            this.errors = [];
            
            if (this.worksheet.task == '') {
                this.errors.push('Veuillez décrire la tâche');
            }
            if (!this.worksheet.surface || this.worksheet.surface == '') {
                this.errors.push("Veuillez donner la surface");
            }
            if (!this.chosenCategory || this.chosenCategory == '' || isNaN(this.chosenCategory) || this.chosenCategory <= 0) {
                this.errors.push("Veuillez choisir une catégorie de la liste");
            }

            if (this.chosenCategory == 3) {
                if (Object.values(this.worksheet.content.toiture).some((field) => { return field == '' })) {
                    this.errors.push("Veuillez remplir tous les champs de la description technique");
                }
            }

            if (this.chosenCategory == 4) {
                if (Object.values(this.worksheet.content.isolation).some((field) => { return field == '' })) {
                    this.errors.push("Veuillez remplir tous les champs de la description technique");
                }
            }

            if (this.chosenCategory == 5) {
                if (Object.values(this.worksheet.content.facade).some((field) => { return field == '' })) {
                    this.errors.push("Veuillez remplir tous les champs de la description technique");
                }
            }

            if (this.errors.length == 0) {
                return true;
            } else {
                Swal.fire("Erreur", this.errors[0], "error");
                return false;
            }
        },
        saveAndRedirectToPDF() {
          var redirectToPdf = true;
          var redirectToQuotation = false;
          this.saveWorksheet(redirectToPdf, redirectToQuotation);
        },
        saveAndRedirectToQuotation() {
          var redirectToPdf = false;
          var redirectToQuotation = false;
          if (!this.showSuperAdmin && !this.showAdmin && !this.showSuperSales) {
            redirectToQuotation = true;
          }
          this.saveWorksheet(redirectToPdf, redirectToQuotation);
        },
        saveWorksheet(redirectToPdf, redirectToQuotation) {
            if (this.worksheetValid()) {
                if (this.processing === true) {
                    return;
                }

                this.processing = true;
                var that = this;
                this.worksheet.serviceCategory = '/api/service_categories/' + this.chosenCategory;
                this.worksheet.sell = '/api/sales/' + this.sell.id;
                this.worksheet.surface = parseInt(this.worksheet.surface);
                
                if (this.type === "update") {
                    api
                        .patch('/work_sheets/' + this.sell.workSheet.id, this.worksheet, {
                            headers: {
                                'Content-Type': 'application/merge-patch+json'
                            }
                        })
                        .then(
                            (response) => {
                                if (response && (response.status == 200)) {
                                  Utilities.successmsg("La fiche chantier a bien été modifiée !");
  
                                  this.redirect(redirectToPdf, redirectToQuotation);
                                  this.processing = false;
                                }
                            },
                            () => {
                                Utilities.errormsg();
                                this.processing = false;
                            }
                        )
                }

                if (this.type === "create") {
                    api
                        .post('/work_sheets', this.worksheet)
                        .then(
                            (response) => {
                                if (response && response.status == 201) {
                                    Utilities.successmsg("La fiche chantier a bien été créée !");
  
                                    this.redirect(redirectToPdf, redirectToQuotation);
                                    this.loadSell(that.sell.id);
                                    this.processing = false;
                                }
                            },
                            () => {
                                Utilities.errormsg();
                                this.processing = false;
                            }
                        )
                }
            }
        },
        redirect(redirectToPdf, redirectToQuotation) {
          var router = this.$router;
          var link = '';

          if (redirectToQuotation) {
            link = '/sell/' + this.sell.id + '/pdf';
          } else if (redirectToPdf) {
            link = '/sell/' + this.sell.id + '/worksheet/pdf';
          }

          if (link != '') {
            setTimeout(function() {
                let routeData = router.resolve({path: link});
                window.open(routeData.href, '_blank');
            }, 2000);
          }
        },
        finishSell() {
            if (this.worksheetValid()) {
                this.$bvModal.show('modal-comment');
            }
        },
        onCommentAdded() {
            this.$bvModal.hide('modal-comment');
            Utilities.successmsg("Le commentaire a bien été ajouté !");

            var router = this.$router;
            setTimeout(function() {
                router.push('/sales');
            }, 2000);
        }
    },
    middleware: "authentication",
  };
</script>

<style lang="scss" scoped>
  ::v-deep .input-group-append {
    width: 70px !important;
  }
</style>