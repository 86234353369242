<template>
    <div>
        <WorksheetToiture :content="content" :required="!exclude && mainCategory === 3" v-if="(!exclude && mainCategory === 3) || (exclude && mainCategory !== 3)"></WorksheetToiture>
        <WorksheetIsolation :content="content" :required="!exclude && mainCategory === 4" v-if="(!exclude && mainCategory == 4) || (exclude && mainCategory != 4)"></WorksheetIsolation>
        <WorksheetFacade :content="content" :required="!exclude && mainCategory === 5" v-if="(!exclude && mainCategory == 5) || (exclude && mainCategory != 5)"></WorksheetFacade>
    </div>
</template>

<script>
  import WorksheetToiture from "@/forms/worksheet/worksheet-toiture";
  import WorksheetIsolation from "@/forms/worksheet/worksheet-isolation";
  import WorksheetFacade from "@/forms/worksheet/worksheet-facade";

    export default {
        name: 'WorksheetContent',
        components: {
            WorksheetToiture,
            WorksheetIsolation,
            WorksheetFacade
        },
        data() {
            return {
            }
        },
        props: ["content", "exclude", "mainCategory"],
    }
</script>