<template>
    <div class="card-body">
        <h3 class="mb-4 mt-0"><u>Humidité/Façades</u></h3>
        <form>
            <div class="row">
                <div class="col-md-3">
                    <p class="mb-1">Type de mur :</p>
                    <input
                        v-model="content.facade.wallType"
                        type="text"
                        name="wallType"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Couleur de tuile :</p>
                    <input
                        v-model="content.facade.wallThickness"
                        type="text"
                        name="wallThickness"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Type de revêtement :</p>
                    <input
                        v-model="content.facade.coveringType"
                        type="text"
                        name="coveringType"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Couleur :</p>
                    <input
                        v-model="content.facade.coveringColor"
                        type="text"
                        name="coveringColor"
                        class="form-control"
                        :required="required"
                    />
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-3">
                    <p class="mb-1">Couleur injecteur :</p>
                    <input
                        v-model="content.facade.injectorColor"
                        type="text"
                        name="injectorColor"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Échaufaudage :</p>
                    <input
                        v-model="content.facade.scaffolding"
                        type="text"
                        name="scaffolding"
                        class="form-control"
                        :required="required"
                    />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        name: 'WorksheetFacade',
        props: ['content', 'required']
    }
</script>