<template>
    <div class="card-body">
        <h3 class="mb-4 mt-0"><u>Isolation</u></h3>
        <form>
            <div class="row">
                <div class="col-md-3">
                    <p class="mb-1">Combles aménagés :</p>
                    <input
                        v-model="content.isolation.accessibleAttic"
                        type="text"
                        name="accessibleAttic"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Combles perdus :</p>
                    <input
                        v-model="content.isolation.lostAttic"
                        type="text"
                        name="lostAttic"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Hauteur de flèche comble :</p>
                    <input
                        v-model="content.isolation.arrowHeight"
                        type="text"
                        name="arrowHeight"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Écartement chevrons :</p>
                    <input
                        v-model="content.isolation.rafter"
                        type="text"
                        name="rafter"
                        class="form-control"
                        :required="required"
                    />
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-3">
                    <p class="mb-1">Isolant à évacuer :</p>
                    <input
                        v-model="content.isolation.insulatorToRemove"
                        type="text"
                        name="insulatorToRemove"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Isolant à poser :</p>
                    <input
                        v-model="content.isolation.insulatorToAdd"
                        type="text"
                        name="insulatorToAdd"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Présence d'une ventilation :</p>
                    <input
                        v-model="content.isolation.ventilation"
                        type="text"
                        name="ventilation"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Boite de dérivation :</p>
                    <input
                        v-model="content.isolation.shuntBox"
                        type="text"
                        name="shuntBox"
                        class="form-control"
                        :required="required"
                    />
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-3">
                    <p class="mb-1">Conduit de cheminée :</p>
                    <input
                        v-model="content.isolation.shaft"
                        type="text"
                        name="shaft"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Présence HPV sous tuile :</p>
                    <input
                        v-model="content.isolation.hpv"
                        type="text"
                        name="hpv"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Trappe d'accès :</p>
                    <input
                        v-model="content.isolation.trap"
                        type="text"
                        name="trap"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Plancher existant :</p>
                    <input
                        v-model="content.isolation.floor"
                        type="text"
                        name="floor"
                        class="form-control"
                        :required="required"
                    />
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Trou d'homme :</p>
                    <input
                        v-model="content.isolation.hole"
                        type="text"
                        name="hole"
                        class="form-control"
                        :required="required"
                    />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        name: 'WorksheetIsolation',
        props: ['content', 'required']
    }
</script>