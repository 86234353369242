<template>
    <div class="card-body pt-0">
        <h3 class="mb-4 mt-0"><u>Toiture</u></h3>
        <form>
            <div class="row">
                <div class="col-md-3">
                    <p class="mb-1">Type de tuile :</p>
                    <input
                        v-model="content.toiture.tileType"
                        type="text"
                        name="tileType"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Couleur de tuile :</p>
                    <input
                        v-model="content.toiture.tileColor"
                        type="text"
                        name="tileColor"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Type de charpente :</p>
                    <input
                        v-model="content.toiture.structure"
                        type="text"
                        name="structure"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Type de gouttière :</p>
                    <input
                        v-model="content.toiture.gutter"
                        type="text"
                        name="gutter"
                        class="form-control"
                        :required="required"
                    />
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-3">
                    <p class="mb-1">Hauteur sous gouttière façade avant :</p>
                    <input
                        v-model="content.toiture.heightFront"
                        type="text"
                        name="heightFront"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Hauteur sous gouttière façade arrière :</p>
                    <input
                        v-model="content.toiture.heightBack"
                        type="text"
                        name="heightBack"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Hauteur de faîtage :</p>
                    <input
                        v-model="content.toiture.ridgeTiling"
                        type="text"
                        name="ridgeTiling"
                        class="form-control"
                        :required="required"
                    />
                </div>
                <div class="col-md-3 mt-md-0 mt-3 pe-md-">
                    <p class="mb-1">Échaufaudage :</p>
                    <input
                        v-model="content.toiture.scaffolding"
                        type="text"
                        name="scaffolding"
                        class="form-control"
                        :required="required"
                    />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        name: 'WorksheetToiture',
        props: ['content', 'required']
    }
</script>